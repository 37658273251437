@font-face {
  font-family: "rajdhani-regular";
  src: url("../../fonts/rajdhani-regular.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../../fonts/rajdhani-semibold.ttf");
}
@font-face {
  font-family: "rajdhani-bold";
  src: url("../../fonts/rajdhani-bold.ttf");
}
@font-face {
  font-family: "rajdhani-light";
  src: url("../../fonts/rajdhani-light.ttf");
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../../fonts/rajdhani-medium.ttf");
}
.primary-nav {
  font-family: "rajdhani-medium";
  background-color: #00868d !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 8vh !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  z-index: 1001 !important;
  flex-direction: row !important;
}
.primary-nav .user-name-btn {
  font-size: 1.55vw !important;
  color: #aecb2a !important;
  transition: 0.5s !important;
  text-transform: none;
  font-family: "rajdhani-medium";
}
.primary-nav #company-logo {
  height: 5.5vh;
  margin-left: 10px;
}
#rotateContainer{
  display: none;
  position: fixed;
  z-index: 10001;
  background-color: white;
  inset: 0px;
  width: 100%;
  height: 100%;
}
#showRotateImage{
  position: absolute;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 70vw;
  height: 64vh;
  background-image: url("../../Assets/Images/portraitMode.svg");
  left: 15vw;
  top: 18vh;
}
#rotateText{
  position: relative;
  top: 30vh;
  left: -40vw;
}
#rotateText p{
  text-align: center;
  font-size: 3em;
  line-height: 1.5;
  transform: rotate(90deg);
}
@media screen and (orientation: portrait) and (max-device-width: 900px){
  #rotateContainer{
    display: block;
  }
}
.primary-nav p.submodule-title,
.primary-nav p.admin-school-name {
  margin: 0;
  margin-left: 2vw;
}
.primary-nav p.admin-school-name {
  font-size: 1.8vw !important;
}
.primary-nav p.submodule-title {
  font-size: 1.55vw;
}
.primary-nav p.notes-submodule-title {
  font-size: 3.4vh !important;
}
.primary-nav p.module-name {
  font-family: rajdhani-regular;
}

.sec-nav-btn-container {
  display: flex;
  justify-content: flex-end !important;
}

.sec-nav-btn-container .logout-students-btn {
  margin-right: 1vw;
  color: #b31f77 !important;
  border-radius: 30px;
  border: 1px solid #b31f77;
  background-color: #e88f90;
  padding: 3px 15px;
  transition: 0.5s !important;
  font-size: 1.3vw !important;
  line-height: 1.5;
  font-family: "rajdhani-medium";
}
.sec-nav-btn-container .logout-students-btn:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.user-dropdown{
  margin-left: auto;
  display: flex;
  align-items: center;
}