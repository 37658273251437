@font-face {
  font-family: "rajdhani-regular";
  src: url("../fonts/rajdhani-regular.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../fonts/rajdhani-semibold.ttf");
}
@font-face {
  font-family: "rajdhani-bold";
  src: url("../fonts/rajdhani-bold.ttf");
}
@font-face {
  font-family: "rajdhani-light";
  src: url("../fonts/rajdhani-light.ttf");
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../fonts/rajdhani-medium.ttf");
}
.progress-module-header-container {
  display: flex;
  justify-content: space-between;
}
.progress-module-header,
.overall-progress-module-header {
  font-family: rajdhani-semibold !important;
  font-size: 4vh;
  margin: 1vw;
  margin-top: 4vw !important;
}

.back-to-overall {
  margin: 4vw 1vw 1vw;
  font-size: 3vh;
  cursor: pointer;
}
/* Overall Progress Module Completion */
.module-completion-container {
  width: 100%;
  padding: 0% !important;
}
.module-completion-content {
  padding: 0 2% 1%;
}
.module-completion-row {
  display: flex;
}
.module-completion-row-content-header {
  display: flex;
  text-align: center;
}
.module-completion-row-content {
  width: 100%;
}
.module-completion-module-row {
  display: flex;
  margin: 2vh 0px;
  align-items: center;
}
.module-completion-games,
.module-completion-python {
  width: 90%;
  margin: 0px auto;
  background-color: #cecece !important;
  height: 4vh;
  border-radius: 1.4vh;
}
.module-completion-games-content,
.module-completion-python-content {
  border-radius: 1.4vh;
  height: 100%;
  align-items: center;
  display: flex;
}
.module-completion-games-content,
.module-completion-python-content {
  background-color: #25a596 !important;
}
.module-completion-game-give-up {
  height: calc(100% - 0.8vh);
  border: 0.4vh solid rgba(179, 10, 116, 0.7);
}
.module-completion-games-progress,
.module-completion-python-progress {
  margin-left: 0.5vw;
  color: white !important;
  font-size: 2.5vh;
}
.module-completion-puzzles {
  display: flex;
  color: white !important;
  font-size: 2.5vh;
}
.module-completion-puzzles-locked .module-completion-puzzles-status{
  background-color: #cecece !important;
}

.module-completion-puzzles-status {
  height: 4vh;
  width: 4vh;
  border-radius: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.module-completion-puzzles-correct-status {
  background-color: #aecb2a;
}
.module-completion-puzzles-incorrect-status {
  background-color: #ff9a9b;
}
.module-completion-puzzles-gave-up-status {
  background-color: rgba(179, 10, 116, 0.7);
}
.horizontal-bar-container {
  width: calc(50% - 6vh);
  display: flex;
  align-items: center;
}
.horizontal-bar-container .horizontal-bar {
  width: 100%;
  height: 1px;
  background-color: #BCBBBB;
}
.color-status-container {
  font-size: 2vh;
  display: flex;
}
.module-completion-color-status {
  display: flex;
  align-items: center;
  padding-left: 1vh;
}
.module-completion-color-status-dot {
  width: 1.5vh;
  height: 1.5vh;
  border-radius: 50%;
}
.module-completion-color-status-dot.correct {
  background-color: #aecb2a !important;
}
.module-completion-color-status-dot.incorrect {
  background-color: #ff9a9b !important;
}
.module-completion-color-status-dot.gave-up {
  background-color: rgba(179, 10, 116, 0.7) !important;
}
.module-completion-color-status-lable {
  padding-left: 1vh;
}

/* Overall Progress Skill Profile */
.skill-profile-container {
  font-size: 3vh !important;
}
.skill-profile-row {
  display: flex;
  width: 90%;
  min-height: 4vh;
  border-radius: 1.5vh;
  background-color: rgb(206, 206, 206);
  margin: 3vh auto;
  overflow: hidden;
  font-family: 'rajdhani-medium';
}
.skill-profile-label {
  width: 66%;
  align-items: center;
  display: flex;
  padding-left: 2% !important;
  padding: 0.5vh;
}
.skill-profile-status {
  width: 30%;
  align-items: center;
  display: flex;
  padding-left: 2%;
  justify-content: center;
  color: white;
}
