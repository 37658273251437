@font-face {
  font-family: "rajdhani-regular";
  src: url('./fonts/rajdhani-regular.ttf');
}

@font-face {
  font-family: "rajdhani-semibold";
  src: url('./fonts/rajdhani-semibold.ttf');
}

@font-face {
  font-family: "rajdhani-bold";
  src: url('./fonts/rajdhani-bold.ttf');
}

@font-face {
  font-family: "rajdhani-light";
  src: url('./fonts/rajdhani-light.ttf');
}

@font-face {
  font-family: "rajdhani-medium";
  src: url('./fonts/rajdhani-medium.ttf');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Module Bar */
.arrow-steps .step {
  font-size: 15px;
  font-family: 'rajdhani-medium';
  text-align: center;
  color: black;
  cursor: default;
  margin: 0 0;
  padding: 5px 10px 6px 30px;
  /* min-width: 180px; */
  float: left;
  position: relative;
  background-color: #DBDBDB;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  border-top: 0.9px solid #BEBCBC;
  border-bottom: 0.9px solid #BEBCBC;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -18px;
  width: 0px;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 19px solid #DBDBDB;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 19px solid #BEBCBC;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  font-size: 15px;
  font-family: 'rajdhani-medium';
  background-color: #C9C6C6;
  color: black;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #BEBCBC;
}

.arrow-steps .step:first-child:after {
  border-left-color: #C9C6C6;
}

.arrow-steps .step:nth-last-child(2) {
  border-radius: 0 1.5px 1.5px 0;
  /* border-right: 1px solid black;  */
}

.arrow-steps .step:nth-last-child(2):after {
  content: " ";
  position: absolute;
  top: 0;
  right: -18px;
  width: 0px;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 19px solid #DBDBDB;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:last-child {
  visibility: hidden
}

.arrow-steps .step:last-child:before {
  visibility: visible;
  right: auto;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  left: -1px;
  top: -1px;
  border-left: 20px solid #DBDBDB;
  z-index: 0;
}


.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
  color: #06ac77;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #00868D !important;
}

.arrow-steps .step.current:after {
  border-left: 19px solid #00868D !important;
}

.arrow-steps .step.currentAssigned {
  color: #fff;
  background-color: #00868D !important;
}

.arrow-steps .step.currentAssigned:after {
  border-left: 19px solid #00868D !important;
}

.arrow-steps .step.currentNotAssigned {
  color: black;
  background-color: #adadad !important;
}

.arrow-steps .step.currentNotAssigned:after {
  border-left: 19px solid #adadad !important;
}

.arrow-steps .step.assigned {
  color: #fff;
  background-color: #25a596;
}
.arrow-steps .step.assigned:after {
  border-left: 19px solid #25a596;
}


/* Module Assignment Bar */
.module-arrow-steps {
  line-height: 1.5;
  display: flex;
  width: 80%;
}
.module-arrow-steps .step {
  font-size: 15px;
  font-family: 'rajdhani-medium';
  text-align: center;
  color: black;
  cursor: default;
  margin: 0 0;
  padding: 5px 10px 6px 30px;
  /* min-width: 180px; */
  float: left;
  position: relative;
  background-color: #DBDBDB;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  border-top: 0.9px solid #BEBCBC;
  border-bottom: 0.9px solid #BEBCBC;
}

.module-arrow-steps .step:after,
.module-arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -18px;
  width: 0px;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 19px solid #DBDBDB;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.module-arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 19px solid #BEBCBC;
  z-index: 0;
}

.module-arrow-steps .step:first-child:before {
  border: none;
}

.module-arrow-steps .step:first-child {
  font-size: 15px;
  font-family: 'rajdhani-medium';
  background-color: #DEDEDE;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 0.9px solid #BEBCBC;
}

.module-arrow-steps .step:first-child:after {
  border-left-color: #DEDEDE;
}

.module-arrow-steps .step:nth-last-child(2) {
  border-radius: 0 1.5px 1.5px 0;
  /* border-right: 1px solid black; */
}

.module-arrow-steps .step:nth-last-child(2):after {
  content: " ";
  position: absolute;
  top: 0;
  right: -18px;
  width: 0px;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 19px solid #DBDBDB;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.module-arrow-steps .step:last-child {
  visibility: hidden
}

.module-arrow-steps .step:last-child:before {
  visibility: visible;
  right: auto;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  left: -1px;
  top: -1px;
  border-left: 20px solid #BEBCBC;
  z-index: 0;
}

.module-arrow-steps .step span {
  position: relative;
}

.module-arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
  color: #06ac77;
}

.module-arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.module-arrow-steps .step.current {
  color: black;
  background-color: #25a596;
}

.module-arrow-steps .step.current:after {
  border-left: 19px solid #25a596;
}