.module-side-nav, .notebook-side-nav{
  background-color: #c0ccc8;
  position: fixed;
  top: 8vh;
  left: 0;
  bottom: 0;
  width: 10vw;
}
.module-side-nav a, .notebook-side-nav a {
  display: flex;
}
.module-side-nav svg.nav-button, .notebook-side-nav svg.nav-button {
  width: 6vw;
  transition: 0.5s;
  transform: scale(1);
  border-radius: 50%;
  border: none;
}
.module-side-nav svg.nav-button.active, .notebook-side-nav svg.nav-button.active {
  cursor: pointer;
  transform: scale(1.15);
  border: 2px solid black;
}
.module-side-nav svg.nav-button:not(.active):hover, .notebook-side-nav svg.nav-button:not(.active):hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.5s;
}
.module-side-nav img.bar, .notebook-side-nav img.bar {
  width: 0.1vw;
}
#return-teacher-dashboard {
  margin-left: 2vw;
  font-size: 1.5vw;
}
.module-return-dashboard {
  font-size: 1.8vw;
  cursor: pointer;
  margin: 1vw 0;
}
.module-return-dashboard img {
  width: 15%;
  margin-right: 5px;
  margin-bottom: 3px;
}
