.downloader {
  width: 500px;
  min-height: 128px;
  position: fixed;
  right: 18px;
  bottom: 18px;
  max-height: 700px;
  overflow-y: auto;
  background-color: white;
}
.downloader .card-header {
  color: #fff;
  padding-left: 1.2em;
  background-color: #00868d;
}
.downloader .card .list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
