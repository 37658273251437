@font-face {
  font-family: "rajdhani-regular";
  src: url("../fonts/rajdhani-regular.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../fonts/rajdhani-semibold.ttf");
}
@font-face {
  font-family: "rajdhani-bold";
  src: url("../fonts/rajdhani-bold.ttf");
}
@font-face {
  font-family: "rajdhani-light";
  src: url("../fonts/rajdhani-light.ttf");
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../fonts/rajdhani-medium.ttf");
}

#login-page {
  background: transparent linear-gradient(180deg, #d1d526 0%, #b4dbdd 100%) 0%
    0% no-repeat padding-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  width: 100vw;
  height: 100vh;
  line-height: 1.5;
}
#login-page *,#login-page :after,#login-page :before {
  box-sizing: border-box;
}
#login-page #surrounding {
  position: fixed;
  bottom: 0px;
  right: -19px;
  height: 100vh;
}
#login-page #logo {
  position: fixed;
  top: 27px;
  left: 1vw;
  width: 11vw;
  z-index: 1;
}
#login-page button:focus {
  outline: none;
}
#login-page .navigation {
  display: flex;
  position: fixed;
  right: 3vw;
  top: 10px;
}
#login-page .navigation > button {
  border-radius: 50px;
  padding: 5px 20px;
  margin: 10px;
  border: none;
  font-family: Rajdhani;
  font-size: 2vw;
  transition: 0.5s;
}
#login-page .navigation > button:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
#login-page .navigation > button.contact {
  background-color: #ffb017;
}
#login-page .navigation > button.login {
  background-color: #fe7d00;
}
#login-page #visionBubble {
  position: fixed;
  width: 30%;
  left: 8vw;
  top: 40vh;
}
#login-page #missionBubble {
  position: fixed;
  width: 30%;
  right: 25vw;
  top: 40vh;
}
#login-page .modal-content {
  border: none;
  background: none;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#login-page .modal-body-login {
  height: 70vh;
  margin-left: 4vw;
  margin-top: 3vw;
  width: 53vw;
}

.reset-password-modal {
  height: 100%;
  margin: 5vh 0;
}
.reset-password-modal .row {
  height: 100%;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}
.reset-password-modal img {
  width: 100%;
  align-self: end;
  /* margin-top: 2vw; */
}

.modal-body-login .row {
  height: 100%;
  margin-left: -15px;
  margin-right: -15px;
}

.image-container,
.form-container {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  justify-content: center;
}

.form-container {
  align-items: center;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.modal-body-login img {
  width: 90%;
}
#login-modal h2 {
  font-family: Rajdhani;
  font-size: 10vw;
  margin-bottom: 3.2vh;
  line-height: 130px;
}
.reset-password-modal h2 {
  font-family: Rajdhani;
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 4.8vw;
  text-align: center;
  margin-top: 0 !important;
}
input:focus {
  outline: none;
}

.reset-password-modal a {
  font-size: 1vw;
  margin: 1vw 0;
}
.reset-password-modal a:hover {
  text-decoration: none;
}
.reset-password-modal .submit-button {
  font-family: Rajdhani;
  font-size: 1.5vw;
  border-radius: 20px;
  border: none;
  padding: 3px 10px;
  width: 100%;
  background-color: #fe7d00;
  transition: 0.5s;
  line-height: 1.5;
}
.reset-password-modal .submit-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.modal-body-login input,
.reset-password-modal input {
  font-family: Rajdhani;
  font-size: 2vw;
  border-radius: 20px;
  border: none;
  padding: 3px 10px;
  width: 100%;
  line-height: 1.5;
}
.reset-password-modal input{
  box-sizing: border-box;
}
#password-toggle-icon-button {
  margin-left: -2.5vw;
  cursor: pointer;
  font-size: 1.5vw;
  width: 1.5vw;
  color: black;
}
#visibility-icon,
#visibilityoff-icon {
  width: 2vw;
  height: 2vw;
}

#username,
#reset-password-username {
  margin-bottom: 1rem !important;
}
.modal-body-login a {
  margin: 1vw 0;
  color: #007bff;
  text-decoration: none;
}
.modal-body-login a:hover {
  text-decoration: none;
}
.modal-body-login .submit-button {
  font-family: Rajdhani;
  font-size: 2vw;
  border-radius: 20px;
  border: none;
  padding: 3px 10px;
  width: 100%;
  background-color: #fe7d00;
  transition: 0.5s;
  line-height: 1.5;
}
.modal-body-login .submit-button:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
#password-reset-button {
  color: #00868d!important;
  padding: 0.1vw 0.4vw;
  font-family: "rajdhani-medium";
}
#login-page p.error {
  color: red;
  font-size: 1.25vw;
  margin-bottom: 0;
  margin-top: 1vw;
  font-family: 'Rajdhani-Medium';
}
.reset-password-modal p.error {
  margin-bottom: 1vw;
  margin-top: 0;
}
.request-reset-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.2vh;
}
.reset-instructions-message {
  font-family: Rajdhani;
  font-size: 1.5vw;
  line-height: 1.8vw;
  text-align: center;
  margin: 0.5rem 1rem;
}
#login-text {
  font-size: 5rem;
  margin-bottom: 1vw;
  margin-top: 1vw;
  font-family: 'rajdhani-semibold';
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.playstore-link-container {
  display: flex;
  border-radius: 20px;
  padding: 10px;
  background-color: black;
  color: white !important;
  height: calc(2.7rem + 20px);
  align-items: center;
  margin-top: 2.5vw !important;
}

.playstore-link-container:hover {
  transform: scale(1.1);
  transition: 0.5s;
  color: white !important;
}

.playstore-link-container img {
  height: 2.7rem;
}

.playstore-link-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  white-space: nowrap;
}

.playstore-link-label span:nth-child(1) {
  font-size: 0.8rem;
}

.playstore-link-label span:nth-child(2) {
  font-size: 1.2rem;
}
/* #height of row must be inherit  */
@media only screen and (orientation: portrait) {
  #login-page #surrounding {
    display: none;
  }
  #login-page .logo-image {
    width: 227.5px;
    height: 125px;
  }
  #login-page .modal-body-login {
    width: 100vw;
    padding-left: 0 !important;
    margin: 0 !important;
  }

  #login-page .modal-body-login .row {
    height: max-content;
    margin-top: 12vh !important;
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
    flex-wrap: unset;
    height: max-content;
  }
  #login-page .modal-body-login .form-container #login-text {
    font-size: 7rem !important;
  }
  #login-page .modal-body-login .form-container,
  #login-page .modal-body-login .image-container {
    flex-basis: unset !important;
    flex-grow: unset !important;
    align-items: center !important;
  }
  #login-page .modal-body-login .form-container {
    margin-top: 5vh;
  }
  #login-page .modal-body-login .image-container img {
    width: 65% !important;
  }
  #login-page .login-form {
    width: 80% !important;
  }

  #login-page #password-toggle-icon-button {
    margin-left: -7vw;
    width: 5vw;
    height: 5vw;
  }
  #login-page #visibility-icon,#login-page #visibilityoff-icon {
    height: 5vw;
    width: 5vw;
}

  .playstore-link-container {
    height: calc(4.7rem + 30px)!important;
    margin-top: 3vw!important;
    padding: 15px;
  }
  .playstore-link-label span:first-child {
    font-size: 1.5rem !important;
  }
  .playstore-link-label span:nth-child(2) {
    font-size: 2.5rem !important;
  }
  .playstore-link-container img {
    height: 4.7rem !important;
  }
  #login-page .modal-body-login .submit-button,
  #login-page .modal-body-login input,
  .reset-password-modal h2 {
    font-size: 4.5vw !important;
  }
  .reset-password-modal .submit-button,
  .reset-password-modal input {
    font-size: 3.5vw !important;
  }

  #password-reset-button {
    font-size: 3vw;
    margin: 3vw 0;
  }
  #login-page #submit-button {
    margin-bottom: 2vw;
  }
  #login-page p.error {
    font-size: 3.5vw;
    margin-top: 2vw;
  }
  .reset-password-dialog .MuiPaper-root {
    max-width: 80vw !important;
    width: 80vw !important;
  }
}
