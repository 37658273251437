@font-face {
  font-family: "rajdhani-regular";
  src: url("../fonts/rajdhani-regular.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../fonts/rajdhani-semibold.ttf");
}
@font-face {
  font-family: "rajdhani-bold";
  src: url("../fonts/rajdhani-bold.ttf");
}
@font-face {
  font-family: "rajdhani-light";
  src: url("../fonts/rajdhani-light.ttf");
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../fonts/rajdhani-medium.ttf");
}

.notes-content {
  font-family: "rajdhani-medium";
  margin-top: 8vh;
  padding: 1rem !important;
  margin-left: 0 !important;
  line-height: 1.2 !important;
}
.rendernotes {
  width: fit-content;
  padding: 15px;
  background: white;
}
.rendernotes h1 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 500;
}
.rendernotes h2,
.rendernotes h3 {
  font-size: 1.75rem;
  margin: 0;
  font-weight: 500;
}
.rendernotes h4 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 500;
}
.rendernotes p {
  margin: 0.5rem 0;
}
