@font-face {
  font-family: "rajdhani-regular";
  src: url("../fonts/rajdhani-regular.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../fonts/rajdhani-semibold.ttf");
}
@font-face {
  font-family: "rajdhani-bold";
  src: url("../fonts/rajdhani-bold.ttf");
}
@font-face {
  font-family: "rajdhani-light";
  src: url("../fonts/rajdhani-light.ttf");
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../fonts/rajdhani-medium.ttf");
}
html {
  background-color: #e2e7ea;
}
body {
  margin: 0;
  padding: 0;
}
.side-nav {
  font-family: "rajdhani-medium";
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 24vh;
  bottom: 0;
  left: 0;
  width: 15%;
}

.side-nav button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  height: 25%;
  width: 100%;
  border: none;
  border-radius: 0;
  background: #e2e7ea;
  padding: 2%;
  border-right: 1px solid #B1B1B6;
}
.side-nav button:hover {
  text-decoration: none;
}

.side-nav button > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90%;
}
.side-nav button > div:hover {
  transition: 0.5s;
  transition-property: transform();
  transform: scale(1.1);
}
.side-nav button div div {
  display: flex;
  height: 77%;
  justify-content: start;
  width: 55%;
}
.side-nav .community div div {
  width: 59%;;
}
.side-nav button:hover p {
  font-family: "rajdhani-medium";
}
.side-nav button p {
  color: #000;
  font-family: rajdhani-medium;
  font-size: 1.5vw;
  margin:0;
  transition: 0.5s;
  line-height: 1;
}
.side-nav-tab-selected {
  font-family: rajdhani-semibold !important;
}
.side-nav button img {
  background-repeat: no-repeat;
  background-size: contain;
  height: 90%;
  width: 100%;
}

.side-nav .selected {
  border-right: 0.4vw solid #b30a74 !important;
  background-color: rgb(255, 154, 155, 0.25);
}
.content {
  margin-top: 24vh;
  margin-left: 15%;
  font-family: "rajdhani-medium";
  overflow: hidden;
}

.content.progress-container {
  padding: 0px !important;
}

/* Lets Work */
.letswork-content {
  padding: 0 !important;
}
.locked-box,
.progress-locked-box {
  margin: auto;
  padding: 2vw;
  border-radius: 1vw;
  margin: 10vw 1vw auto
}

.no-community-playground {
  margin: 8vw 4vw auto;
  padding: 2vw;
  background-color: #f5f5f5;
  border-radius: 1vw;
}

.locked-box p,
.progress-locked-box p,
.no-community-playground p {
  text-align: center;
  line-height: 2.2vw;
  font-size: 4.5vh;
  margin: 0;
}
.module-header,
.dashboard-module-header {
  font-size: 1.8vw;
  margin: 0;
  font-family: "rajdhani-semibold";
}
.dashboard-module-header {
  margin: 1vw !important;
  margin-top: 4vw !important;
}
.module-details {
  display: flex;
  padding: .5vw 1vw 0vw;
}
.module-buttons-container {
  width: 22%;
}
.module-image-container {
  width: 45%;
  margin-bottom: 2vh;
}
.module-calender-container {
  width: 33%;
  padding: 0 0 0 2vw;
}

.module-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.module-buttons {
  margin-top: 2vw;
}
.module-container-labels {
  margin-top: 1vw;
  margin-bottom: 0.7vw;
  display: flex;
  flex-direction: column;
  font-size: 2vw;
  justify-content: space-between;
}
.module-container-labels p {
  margin: 0;
  font-family: "rajdhani-medium";
  font-size: 1.5vw;
  width: 100%;
}
.module-container svg.nav-button {
  width: 4.5vw;
  transition: 0.5s;
  display: block;
}
.module-container svg.nav-button:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.5s;
}
.disable-exercise-notebook {
  pointer-events: none;
  opacity: 0.5;
}
.module-container img.bar {
  width: 0.08vw;
}
.content-content .snapshot-holder {
  overflow: hidden;
  border-radius: 6%;
  height: 100%;
}
.content-content .snapshot-holder:hover img {
  transform: scale(1.1);
  transition: 0.5s;
  transition-timing-function: ease;
}
.snapshot-img {
  cursor: pointer;
  width: 100%;
  transition: 0.5s;
  transition-timing-function: ease;
  height: 100%;
}
.module-container-labels div {
  width: 100%;
  display: flex;
  align-items: center;
}
.module-container-labels .ongoing-pointer p {
  font-family: Rajdhani-SemiBold !important;
}
.module-container-labels .ongoing-pointer::after {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  transform: translateX(0);
  overflow: hidden;
  border-bottom: 1.35vw solid #0000;
  border-right: 1.8vw solid #ff9a9b;
  border-top: 1.35vw solid #0000;
}
.footer-box > .assignment-box,
.footer-box > .badges-box {
  height: 3vw;
}
.assignment-box p {
  margin: 0;
  font-size: 1.4vw;
  line-height: 1.5vw;
}
.module-calender {
  margin: auto;
  height: 50vh;
  width: 80%;
}
.badges-box p {
  font-size: 2vw;
  margin-bottom: 0;
  margin-right: 1vw;
}
/* Progress */

.module-bar .step {
  font-size: 15px;
  font-family: "rajdhani-medium";
  text-align: center;
  cursor: default;
  margin: 0 0;
  padding: 6px 10px 6px 30px;
  /* min-width: 180px; */
  float: left;
  position: relative;
  background-color: #e2e7ea;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  border-top: 1px solid #C9C9C9;
  border-bottom: 1px solid #C9C9C9;
  cursor: pointer;
  color: black;
}

.module-bar .step:after,
.module-bar .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -18px;
  width: 0px;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 19px solid #e2e7ea;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.module-bar .step:before {
  right: auto;
  left: 0;
  border-left: 19px solid #C9C9C9;
  z-index: 0;
}

.module-bar .step:first-child:before {
  border: none;
}

.module-bar .step:first-child {
  width: 10%;
  font-size: 15px;
  font-family: "rajdhani-medium";
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #C9C9C9;
  color: black;
}
.module-bar .step:last-child {
  border-radius: 0 1.5px 1.5px 0;
  border-right: 2px solid #C9C9C9;
}
.module-bar .step:last-child:after{
  display: none;
} 
.module-bar .step span {
  position: relative;
}
.module-bar .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
  color: #06ac77;
}

.module-bar .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.module-bar .step.completed {
  background-color: #25a596 !important;
}

.module-bar .step.completed:after {
  border-left: 19px solid #25a596 !important;
}

.module-bar .step.incomplete {
  background-color: #fe7d00 !important;
}

.module-bar .step.incomplete:after {
  border-left: 19px solid #fe7d00 !important;
}

.module-bar .step.ongoing {
  background-color: #aecb2a !important;
}
.module-bar .step.ongoing:after {
  border-left: 19px solid #aecb2a !important;
}

.module-bar .step.locked {
  background-color: #DBDBDB;
}
.module-bar .step.locked:after {
  border-left: 19px solid #DBDBDB;
}

.module-bar .step.completed.active {
  background-color: #00868d!important;
}

.module-bar .step.completed.active:after {
  border-left: 19px solid #00868d!important;
}


.module-bar .step.incomplete.active {
  background-color: #fe6a00!important;
}

.module-bar .step.incomplete.active:after {
  border-left: 19px solid #fe6a00!important;
}

.module-bar .step.locked.active {
  background-color: #adadad!important;
}

.module-bar .step.locked.active:after {
  border-left: 19px solid #adadad!important;
}

.module-progress-bar-container,
.module-status-bar {
  position: absolute;
  width: 85%;
  overflow: hidden;
}
.module-status-bar {
  display: flex;
  position: fixed !important;
  flex-direction: column;
  z-index: 1000;
}

.progress-header {
  font-family: rajdhani-semibold !important;
  font-size: 3.5vh !important;
}
.overall-progress-content-container .progress-header {
  padding: 1vh 2vh 2vh 1vh !important;
  margin: 0.5vw 1vw !important;
}
.progress-content-container .progress-header {
  margin: 0.5vw 1vw 0 !important;
}
.module-completion-sub-header {
  font-family: rajdhani-semibold !important;
  margin: 0 1vw !important;
  font-size: 3vh !important;
}
.module-completion-module-label {
  font-family: rajdhani-medium !important;
  margin: 0 1vw !important;
  font-size: 3vh !important;
}
.module-completion-header {
  display: flex;
  justify-content: space-between;
  padding-right: 3vh !important;
}
.progress-content {
  max-height: 76vh;
  overflow: auto;
  overflow-x: hidden;
}

.progress-content h2 {
  font-family: "rajdhani-bold";
  font-size: 3vw;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.progress-content h3 {
  font-family: "rajdhani-bold";
  font-size: 2.5vw;
  font-weight: bold;
  margin: 0;
}
.progress-content hr {
  margin: 0.3vw auto !important;
  width: 90%;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.overall-progress-content-container,
.progress-content-container {
  margin: 0 2vh 2vh !important;
  width: calc(100% - 4vh) !important;
}
.progress-exercise-assesment,
.progress-feedback-rewards,
.module-completion-container,
.skill-profile-recent-rewards {
  justify-content: space-between;
}
.skill-profile-recent-rewards {
  padding: 0 !important;
}
.progress-feedback-rewards,
.skill-profile-recent-rewards {
  padding-top: 3vh !important;
}
.progress-exercise-container,
.progress-assesment,
.progress-feedback,
.progress-rewards,
.module-completion-progress,
.skills-profile,
.recent-rewards {
  background-color: #dbdbdb;
  margin: auto !important;
  height: 100%;
  border-radius: 1vw;
  margin-bottom: 2vh !important;
}
.progress-exercise-container {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}
.progress-exercise-container .demolevel-label {
  width: 100%;
  height: fit-content;
  text-align: left;
  font-size: 1.8vh;
  font-family: "rajdhani-medium";
  margin: 0.5vw 1vw !important;
}
.progress-exercise-container .demolevel-label p {
  margin: 0;
}
.progress-exercise {
  justify-content: space-evenly;
}
.bar-container {
  height: 2vh;
  width: 4vh;
  display: flex;
  justify-content: center;
}
.bar-container .vertical-bar {
  height: 100%;
  width: 1px;
  background-color: #BCBBBB;
}
.assesment-bar-container {
  width: 20% !important;
}

.progress-exercise-type-content {
  margin: 0px 1vw;
}
.exercise-header-row,
.assesment-header-row {
  font-family: "rajdhani-semibold" !important;
  margin-bottom: 1vh !important;
  background: transparent !important;
}
.exercise-header-row-label,
.assesment-header-row-label {
  background-color: transparent !important;
}
.assesment-total-row {
  background-color: transparent !important;
  border: 1px solid #707070;
}
.assesment-total-row-label {
  background-color: transparent !important;
  font-size: 2.5vh !important;
}
.assesment-total-score {
  font-size: 3vh !important;
}

.feedback-box {
  margin: 1vw 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feedback-box > svg {
  height: 4.5vw;
  width: 6vw;
  margin: 0 0.3vw;
}
#assesment-tab {
  padding-top: 2vw;
  padding: 0 2vw;
}
.assesment-notdone-view,
.rewards-coming-soon {
  display: flex;
  flex-direction: column;
  font-family: "rajdhani-medium";
  font-size: 2.5vh;
  text-align: center;
}

.assesment-row,
.exercise-row,
.exercise-header-row,
.assesment-header-row {
  width: 100%;
  text-align: center;
  height: 4vh;
  overflow: hidden;
  background: rgba(209, 213, 38, 0.7);
  font-size: 2.5vh;
  border-radius: 1.4vh;
}
.assesment-row,
.exercise-row {
  font-family: rajdhani-medium !important;
}
.exercise-row,
.exercise-header-row {
  border-top-left-radius: 2vh !important;
  border-bottom-left-radius: 2vh !important;
}
.assesment-row-label,
.exercise-row-label {
  display: flex;
  background-color: #cecece;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
}
.assesment-row-label {
  width: 20%;
}
.exercise-row-label {
  width: 4vh;
  border-radius: 2vh;
}
.assesement-row-content,
.exercise-row-result {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}
.exercise-row-result {
  width: calc(100% - 8vh);
  margin-left: auto;
}
.assesment-empty {
  width: 22%;
}
.assesment-result {
  width: 38%;
}
.assesment-score {
  width: 20%;
}

.gave-up {
  background-color: transparent !important;
}

.gave-up .assesment-empty,
.gave-up .assesment-result,
.gave-up .assesment-score {
  border-top: 0.4vh solid rgba(179, 10, 116, 0.7) !important;
  border-bottom: 0.4vh solid rgba(179, 10, 116, 0.7) !important;
  height: 3.2vh !important;
}
.gave-up .assesment-score {
  border-bottom-right-radius: 1.4vh !important;
  border-top-right-radius: 1.4vh !important;
  border-right: 0.4vh solid rgba(179, 10, 116, 0.7);
  width: calc(20% - 0.4vh);
}
.progress-loading-box {
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}
.game-holder,
.puzzle-holder {
  width: 90%;
}

.headers {
  text-align: center;
  margin-top: 1vw;
  width: 100%;
  padding-left: 1.5vw;
}
.headers p {
  font-family: "rajdhani-bold";
  font-weight: bold;
  font-size: 1.5vw;
  margin-bottom: 0.2vw;
  margin-top: 0;
}
.headers .col {
  flex: 1;
}
.table-cells > div {
  border-radius: 2vw;
  height: 2.5vw;
  margin-bottom: 0.5vw;
}
.table-cells .row {
  width: 100%;
  padding-left: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-cells .col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.table-cells .good {
  background-color: #72b7ba;
}
.table-cells .bad {
  background-color: #f69940;
}
.table-cells .grey {
  background-color: #cecece;
}
.table-cells .grey .total-header {
  font-weight: bold;
  font-size: 1.5vw;
}
.table-cells p {
  font-family: "rajdhani-medium";
  font-size: 1.7vw;
  margin: 0vw;
}
.construction-img img {
  width: 70vw;
  height: 60vh;
}
.parents-content {
  max-height: 76vh;
  overflow: auto;
  overflow-x: hidden;
}
.parents-content .parents-markdown {
  margin-top: 4vw !important;
  background-color: white;
  margin: 1vw;
  padding: 3vw;
  font-size: 1.3vw;
  text-wrap: wrap;
}
.parents-content .parents-markdown > h1 {
  margin-top: 0;
}
.parents-content p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.calender-view {
  width: 100%;
  height: 18vw;
  margin: auto;
  border-top-left-radius: 6% 9%;
  border-bottom-left-radius: 6% 9%;
  border-top-right-radius: 6% 9%;
  border-bottom-right-radius: 6% 9%;
  overflow: hidden;
}

.calender-view .MuiDayPicker-weekContainer .Mui-selected {
  color: inherit !important;
  font-weight: 400 !important;
  background-color: white;
}

.calender-view .MuiDayPicker-weekContainer .Mui-selected:focus {
  background-color: white;
}

.calender-view .MuiPickerStaticWrapper-root {
  height: 100%;
  background-color: rgba(255, 140, 119, 0.42);
  background-color: #f9b4d6;
}

.calender-view .MuiPickerStaticWrapper-root .MuiPickerStaticWrapper-content {
  height: 100%;
  min-width: 100%;
}

.calender-view .MuiCalendarOrClockPicker-root {
  height: 100%;
}

.calender-view .MuiCalendarOrClockPicker-root > div {
  width: 100%;
  height: 100%;
}

.calender-view .MuiCalendarOrClockPicker-root > div .MuiCalendarPicker-root {
  width: 100%;
  padding: 1vw 1vw 0;
  margin: auto;
  overflow: hidden;
  height: 100% !important;
  box-sizing: border-box;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersCalendarHeader-root {
  margin: 0;
  padding: 0;
  max-height: 25%;
  min-height: 25%;
  border-bottom: 1px solid #dddddd;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersCalendarHeader-root
  .MuiPickersArrowSwitcher-spacer {
  display: none;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersCalendarHeader-root
  .Mui-disabled {
  opacity: 0.6;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  > div:last-child {
  height: 75%;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  > div:last-child
  > div {
  height: 100%;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersCalendarHeader-labelContainer {
  font-family: rajdhani-medium;
  height: 100%;
  max-height: 100%;
  flex: 1;
  pointer-events: none;
}
.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersCalendarHeader-labelContainer
  .MuiPickersFadeTransitionGroup-root {
  width: 40%;
}

.calender-view .MuiPickersCalendarHeader-label {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.3vw !important;
  border-right: 1px solid #BABABA;
  height: 100%;
  padding-right: 2px;
  width: 35%;
  justify-content: center;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersCalendarHeader-labelContainer
  .module-session-details {
  display: flex;
  flex-direction: column;
  font-size: 0.95vw;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersCalendarHeader-labelContainer
  .MuiPickersCalendarHeader-switchViewButton {
  display: none;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersFadeTransitionGroup-root {
  height: 100%;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersFadeTransitionGroup-root
  > div {
  height: 100%;
  padding-right: 2px;
  justify-content: center;
  width: initial !important;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiCalendarPicker-root
  .MuiPickersFadeTransitionGroup-root
  > div
  > div {
  height: 100%;
}

.calender-view .MuiDayPicker-header {
  height: 15%;
}

.calender-view .PrivatePickersSlideTransition-root {
  height: 85%;
  min-height: 85%;
}

.calender-view
  .MuiCalendarOrClockPicker-root
  > div
  .MuiPickersFadeTransitionGroup-root
  > div
  > div
  .PrivatePickersSlideTransition-root {
  height: 85%;
  overflow: hidden;
  min-height: 85%;
}

.calender-view .MuiDayPicker-monthContainer {
  height: 100%;
  overflow-y: auto;
}

.calender-view .MuiDayPicker-weekContainer {
  height: 15%;
  align-items: center;
  margin: 0;
}

.calender-view .MuiDayPicker-weekDayLabel,
.calender-view .MuiPickersDay-root {
  width: 100%;
  height: 100%;
  font-size: 0.8vw !important;
}

.calender-view .MuiPickersArrowSwitcher-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0) invert(1);
}

.calender-view .MuiPickersArrowSwitcher-button svg {
  display: none;
}

.calender-view .MuiPickersArrowSwitcher-button > div {
  width: 0.6vw;
  height: 0.6vw;
  background-color: #ffb017;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.student-dashboard-feedback {
  background-color: #ffcfc680;
  border-radius: 1vw;
  margin: 2.5vh 0 2vh !important;
  padding: 1vh 2vh;
  border-bottom-left-radius: 6% 18%;
  border-bottom-right-radius: 6% 18%;
  border-top-left-radius: 6% 18%;
  border-top-right-radius: 6% 18%;
}
.feedback-header {
  padding: 1vh 3vh 0;
  font-family: rajdhani-medium !important;
  font-size: 1.5vw !important;
}
