@font-face {
  font-family: "Rajdhani";
  src: url("../fonts/rajdhani-medium.ttf");
}
@font-face {
  font-family: "Rajdhani";
  src: url("../fonts/rajdhani-bold.ttf");
  font-weight: bold;
}
#not-found-container {
  background: transparent linear-gradient(180deg, #d1d526 0%, #b4dbdd 100%) 0%
    0% no-repeat padding-box;
  width: 100vw;
  height: 100vh;
  font-family: Rajdhani;
  z-index: 1300;
  position: fixed;
  top: 0;
  left: 0;
  line-height: 1.5;
}
#not-found-container *,#not-found-container :after,#not-found-container :before {
  box-sizing: border-box;
}
#not-found-container .company {
  width: 10vw;
  position: fixed;
  top: 1vw;
  left: 1vw;
}
#not-found-container #surrounding {
  position: fixed;
  width: 100% !important;
  bottom: 0;
  left: 0;
  right: 0;
}
#not-found-container .not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20vw;
  height: 100vh;
  padding-bottom: 40vh;
}
#not-found-container .row {
  width: 100% !important;
}
#not-found-container h2 {
  font-size: 6vw;
  line-height: 5.5vw;
  margin: 0;
  font-weight: normal;
  margin: 0;
}
#not-found-container p.error-code {
  opacity: 0.5;
  font-size: 3vw;
  margin: 0;
  margin-bottom: 1vw;
}
#not-found-container p {
  font-size: 2vw;
}
#not-found-container .not-found-content a:focus {
  outline: none;
  text-decoration: none;
}
#not-found-container .not-found-content a {
  color: black;
  text-align: center;
  z-index: 1000;
  margin: 0;
  font-family: Rajdhani;
  font-size: 2vw;
  border-radius: 1vw;
  border: none;
  padding: 3px 100px;
  width: 50%;
  background-color: #ffb017;
  transition: 0.5s;
}
#not-found-container .not-found-content a:hover {
  color: black;
  text-decoration: none;
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.5s;
}
