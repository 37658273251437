@font-face {
  font-family: "rajdhani-regular";
  src: url("../fonts/rajdhani-regular.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../fonts/rajdhani-semibold.ttf");
}
@font-face {
  font-family: "rajdhani-bold";
  src: url("../fonts/rajdhani-bold.ttf");
}
@font-face {
  font-family: "rajdhani-light";
  src: url("../fonts/rajdhani-light.ttf");
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../fonts/rajdhani-medium.ttf");
}

.datepicker-container {
  z-index: 2000 !important;
}
.datepicker-container li {
  font-size: 1vw;
}
#assign-module-modal .modal-body {
  width: 50vw;
  height: 40vh;
}
.modal-deadline-picker {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.module-dates {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;
  font-size: 1.5vw;
  width: 80%;
  margin: auto;
}
.module-completion-date,
.module-assigned-date {
  width: 100%;
  display: flex;
}
.module-set-completion-date {
  width: 100%;
}
.module-assigned-date > span:first-child,
.module-completion-date > span:first-child {
  margin-right: 2%;
  align-self: center;
}
#assign-module-modal p {
  margin-bottom: 0;
  font-size: 1.5vw;
}
.input-group {
  display: flex;
  flex-wrap: wrap;
  height: 3vw;
  position: relative;
}
.react-datepicker__input-container {
  height: 100%;
}
.react-datepicker__header {
  background-color: white !important;
  border: none !important;
  padding-bottom: 0 !important;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group img {
  width: 2vw;
}
.input-group input {
  font-family: "rajdhani-medium";
  font-size: 1.5vw;
  height: 100%;
  border-radius: 1vw 0 0 1vw;
  border: none;
  background-color: white !important;
  padding-left: 0.7vw;
}
#basic-addon2 {
  height: 100%;
  border-radius: 0 1vw 1vw 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: #dedede;
  margin: 0;
  display: flex;
  padding: 0 1vw;
}
button:focus {
  outline: none;
}

.teacher-dashboard-container {
  height: 100%;
  position: fixed;
  top: 8vh;
  left: 0;
  right: 0;
  line-height: 1.5;
  font-family: "rajdhani-medium";
}
#return-assigned-courses {
  display: flex;
  align-items: center;
}
.return-dashboard .arrow-back-icon {
  width: 32%;
  margin-right: -4px;
  margin-bottom: 2px;
}
.return-dashboard {
  font-size: 1.4vw;
  cursor: pointer;
  margin: 1vw 0;
  cursor: pointer;
}
.teacher-dashboard-module {
  display: flex;
  height: 100%;
}
.teacher-dashboard-side-nav {
  font-family: "rajdhani-medium";
  align-items: center;
  background-color: #cfd8d3;
  display: flex;
  flex-direction: column;
  font-size: 1.5vw;
  overflow-y: auto;
  width: 12vw;
  box-sizing: border-box;
  height: 92vh;
}
.teacher-dashboard-side-nav .module-fetch-button {
  cursor: pointer;
  margin: 0 0 1rem 0 !important;
}
.dashboard-module-content {
  height: 100%;
  margin: 0;
  padding: 0.3vw 2vw 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: "rajdhani-medium";
}
.teacher-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  padding: 0.5vw 0;
}
.teacher-dashboard-header p {
  font-size: 2vw;
  margin: 0;
  font-weight: 100;
}
.assign-module-button,
.confirm-deadline,
.see-work,
.view-module {
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  padding: 5px 20px;
  transition: 0.5s;
  font-size: 1.3vw;
  font-family: inherit;
  line-height: 1.5;
}
.see-work {
  background-color: #f1bdbf;
  margin-bottom: 1vw;
  margin-left: 1vw;
  font-size: 1.5vw;
  width: 40%;
  padding: 0 5vw;
}
.see-work-icon {
  height: 1.5vw;
}
.assign-module-button:hover,
.confirm-deadline:not(:disabled):hover,
.see-work:hover,
.view-module:hover {
  transform: scale(1.05);
  transition: 0.5s;
}
.confirm-deadline {
  font-size: 1.5vw !important;
  margin-top: 1vw !important;
  padding: 5px 20px !important;
  width: 95% !important;
  margin: 1vw 2.5% 1vw;
}
.dashboard-module-content .assigned-date {
  border-radius: 1vw;
  padding: 0.1vw 0.3vw;
}
.dashboard-module-content .deadline-date {
  cursor: pointer;
  border-radius: 1vw;
  padding: 0.1vw 0.4vw;
}
.dashboard-module-content .deadline-date:hover {
  background-color: #cccccc;
}
.assigned-display {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.assigned-display > p {
  font-size: 1.3vw;
  line-height: 1.6vw;
}
.dashboard-module-content hr {
  border-color: #00868d;
  margin: 0.3vw 0;
  opacity: 0.5;
  border-right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-left: 0;
}
.information-box {
  display: flex;
  flex-direction: row;
  margin: 0.5vw 0 0;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
}
.students-box {
  box-sizing: border-box;
  flex-direction: column;
  height: 98%;
  overflow: auto;
  padding: 0 1vw 0 0;
}
.student {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 3vw; */
  margin-bottom: 0.5vw;
  cursor: pointer;
}
.student.active {
  background-color: #d5d5d5;
}
.student:not(.active):hover {
  opacity: 0.75;
}
.student .student-name {
  width: 50%;
}
.student .student-progress-preview {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.3vw;
  width: calc(50% - 0.3vw);
}
.nav-button {
  width: 2vw;
}
.bar {
  width: 1vw;
}
.student p {
  margin: 0;
  font-size: 1.4vw;
  padding: 0.5vw;
}
.student-progress-box {
  padding: 0vw;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-top: 0.7vw;
  overflow: auto;
}
.not-assigned-box {
  width: 75%;
  height: 40%;
  margin: auto;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  border-radius: 1vw;
  background-color: #dddddd;
}
.not-assigned-box h3 {
  font-size: 3vw;
  margin: 0;
  margin-bottom: 0.3vw;
  font-family: "rajdhani-bold";
}
.not-assigned-box p,
.no-module-component p {
  text-align: center;
  font-size: 2vw;
  margin: 0;
}
.no-module-component {
  display: flex;
  justify-content: center;
}
.no-module-component p {
  position: absolute;
  margin-top: 2vh;
}
.student-report .report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vw;
  height: 4vh;
}
.student-username-password {
  font-size: 1.3vw;
  margin: 0;
  display: flex;
  justify-content: end;
  align-items: center;
}
.username-box span:first-child,
.password-box span:first-child {
  font-family: "rajdhani-bold";
}
.student-username-password .visibility-icon {
  cursor: pointer;
  width: 8%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.student-report h2 {
  font-size: 1.7vw;
  font-family: "rajdhani-bold";
  margin: 0;
}
#exercises,
#assesment {
  background-color: #dddddd;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vw;
  border-radius: 1vw;
}
#feedback {
  background-color: #dddddd;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vw;
  border-radius: 1vw;
  width: 60%;
}
#feedback-seework-container {
  display: flex;
}
.star {
  height: 4vw;
  width: 4vw;
  margin: 0 0.4vw;
}
.star:hover {
  cursor: pointer;
}
.star:hover .star-coloring.active {
  opacity: 0.6;
}
.star:hover .star-coloring {
  fill: #ebebeb;
}
.star .star-coloring.active {
  fill: #ffeb00;
}
.star .star-coloring {
  fill: white;
}
.student-report h3 {
  font-size: 2vw;
  font-family: "rajdhani-bold";
  margin: 0;
  margin-bottom: 0.3vw;
}
.table-holder {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.report-table-cell {
  text-align: center;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3vw;
}
.python-table-cell,
.game-table-cell {
  width: 50%;
}
.puzzle-table-cell {
  width: 40%;
}
.report-table-cell h4 {
  font-size: 1.5vw;
  margin: 0 0 0.5rem;
  padding: 0;
  font-weight: normal;
}
table.table-bordered {
  border: 1px solid rgba(90, 90, 90, 0.2);
}
table.table-bordered > thead > tr > th {
  border: 1px solid rgba(90, 90, 90, 0.2);
}
table.table-bordered > tbody > tr > td {
  border: 1px solid rgba(90, 90, 90, 0.2);
}

.report-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.assesment-not-completed {
  margin: 0;
  font-size: 1.3vw;
}
.assigned-module {
  color: #00868d;
}
.selected-module {
  font-family: "rajdhani-bold";
}

.sec-nav-btn-container .download-report-btn {
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  padding: 5px 20px;
  transition: 0.5s;
  font-size: 1.3vw;
  margin-right: 1vw;
  display: flex;
  align-items: center;
}

#confirm-logout-students,
#denied-logout-students {
  border-radius: 10px;
  border: none;
  background-color: #ffb017;
  padding: 2px 12px;
  transition: 0.5s;
  font-size: 1.5vw;
  width: 5vw;
}

#confirm-logout-students:hover,
#denied-logout-students:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.react-datepicker-popper {
  position: fixed !important;
}

.completion-date-picker {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}
.teacher-dashboard-module-content {
  width: calc(100% - 12vw);
  height: 90vh;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.student-report .demolevel-label {
  width: 100%;
  height: fit-content;
  text-align: left;
  font-size: 1vw;
  font-family: "rajdhani-medium";
  margin: 0 1vw !important;
}
.student-report .demolevel-label p {
  margin: 0;
}
