@font-face {
  font-family: "Rajdhani";
  src: url("../fonts/rajdhani-medium.ttf");
}
@font-face {
  font-family: "Rajdhani";
  src: url("../fonts/rajdhani-bold.ttf");
  font-weight: bold;
}
.reset-password-container {
  background: transparent linear-gradient(180deg, #d1d526 0%, #b4dbdd 100%) 0%
    0% no-repeat padding-box;
  width: 100vw;
  height: 100vh;
  font-family: Rajdhani;
  z-index: 1300;
  position: fixed;
  top: 0;
  left: 0;
  line-height: 1.5;
}
.reset-password-container *,.reset-password-container :after,.reset-password-container :before {
  box-sizing: border-box;
}
.reset-password-container .company {
  width: 10vw;
  position: fixed;
  top: 1vw;
  left: 1vw;
}
.reset-password-container #surrounding {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.reset-password-container .reset-password-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20vw;
  z-index: 1001;
}
.reset-password-container h2 {
  font-size: 6vw;
  margin: 0;
  font-weight: normal;
  margin-top: 5vw;
  margin-bottom: 2vw;
}
.reset-password-container p {
  margin: 1vw 0;
  font-size: 2vw;
  text-align: center;
}
.reset-password-container .reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  z-index: 1001;
  line-height: 1.5;
}
.reset-password-container button:focus {
  outline: none;
}
.reset-password-container input:focus {
  outline: none;
}
.reset-password-container input {
  font-family: Rajdhani;
  font-size: 2vw;
  border-radius: 1vw;
  border: none;
  padding: 3px 10px;
  width: 100%;
  margin-bottom: 1vw;
  line-height: 1.5 !important;
}
.reset-password-container .submit-button-reset {
  z-index: 1000;
  margin: 0;
  font-family: Rajdhani;
  font-size: 2vw;
  border-radius: 1vw;
  border: none;
  padding: 3px 10px;
  width: 100% !important;
  background-color: #ffb017;
  transition: 0.5s;
}
.reset-password-container .submit-button-reset:not([disabled]):hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.5s;
}
.reset-password-container .error {
  color: red;
  font-size: 1.3vw;
  margin: 0;
  margin-top: -0.4vw;
  margin-bottom: 0.5vw;
}
.reset-password-container .password-checker {
  width: 100%;
  margin-bottom: 1vw;
  margin-top: 1vw;
}
.reset-password-container .password-checker p {
  margin: 0;
  font-size: 1.3vw;
}
.reset-password-container .strength-box {
  background-color: white;
  width: 100%;
  height: 1.3vw;
  border-radius: 2vw;
}
.reset-password-container .strength-indicator {
  background-color: red;
  height: 1.3vw;
  transition: 0.5s;
  width: 0;
  border-radius: 2vw;
}
