@font-face {
  font-family: "rajdhani-regular";
  src: url("../fonts/rajdhani-regular.ttf");
}
@font-face {
  font-family: "rajdhani-semibold";
  src: url("../fonts/rajdhani-semibold.ttf");
}
@font-face {
  font-family: "rajdhani-bold";
  src: url("../fonts/rajdhani-bold.ttf");
}
@font-face {
  font-family: "rajdhani-light";
  src: url("../fonts/rajdhani-light.ttf");
}
@font-face {
  font-family: "rajdhani-medium";
  src: url("../fonts/rajdhani-medium.ttf");
}
html {
  background-color: #e2e7ea;
}
body {
  margin: 0;
  padding: 0;
}

.playground-container {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "rajdhani-regular";
}
.playground-content {
  width: 100%;
  top: 8vh;
  position: fixed;
  height: 90vh;
  margin-bottom: 2vh;
}
.playground-content .playground-content-wrapper {
  width: 96%;
  padding: 0 2%;
  overflow-y: scroll;
  height: 100%;
}
.playground-content .playground-content-wrapper #game-view #blocklyArea {
  height: 400px;
  width: calc(100% - 400px);
}

.playground-content
  .playground-content-wrapper
  #game-view
  #blocklyArea
  #blockly {
  height: 30vw;
  width: 47vw;
}
#playground-container {
  display: flex;
  justify-content: space-between;
}

#playground-container * {
  font-family: "rajdhani-semibold";
}

#playground-container button,
.playground-main-container button {
  font-family: Rajdhani !important;
  font-size: 1.5vw;
}

#playground-container
  button:disabled
  .playground-main-container
  button:disabled {
  background-color: #d3d3d3 !important;
  cursor: default;
}
.actions-wrapper {
  margin: 1vh 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "rajdhani-semibold";
}

.actions-wrapper .return-community-button,
.community-show-more-button {
  color: black;
  text-decoration: none;
}

.actions-wrapper .return-community-button .playground-return-home,
.community-show-more-button {
  font-size: 1.8vw;
  cursor: pointer;
}

.actions-wrapper .return-community-button .playground-return-home img {
  width: 15%;
  margin-right: 5px;
  margin-bottom: 3px;
}

.actions-wrapper .return-community-button .back-text {
  font-family: "rajdhani-medium" !important;
  margin-bottom: 2px;
  text-transform: none !important;
}

.actions-wrapper .game-set-name {
  margin: 0;
}

.actions-wrapper #game-publish-button,
.actions-wrapper #game-delete-button {
  line-height: 2.2vw;
  font-size: 1.5vw;
  background-color: #ffb017;
  font-family: "rajdhani-medium";
}

.actions-wrapper #game-publish-button {
  margin-right: 1vw;
}

.actions-wrapper .student-name {
  font-family: "rajdhani-semibold";
  font-size: 1.5vw;
  margin: 0;
}

.community-container {
  max-height: 76vh;
  overflow-y: auto;
  padding: 0 !important;
}

.community-container .community-container-content {
  margin-left: 0 !important;
  height: 100%;
  width: 100% !important;
}

.community-container
  .community-container-content
  .user-community-project-wrapper {
  padding-left: 0 !important;
  height: 100%;
  overflow-y: auto;
}

.community-container .community-container-content .create-project-wrapper {
  height: 100%;
  overflow-y: auto;
}

.playground-headings {
  font-family: "rajdhani-semibold" !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 4vh !important;

}
.select-project-heading {
  margin: 2vh 2vh 0vh !important;
}

.playground-projects-container,
.playground-create-projects-container {
  display: flex !important;
  flex-wrap: wrap;
}

.playground-projects-container .project-card,
.playground-create-projects-container .project-card {
  transition: transform 0.5s ease-in-out;
}

.playground-projects-container .project-card .project-card-content-container,
.playground-create-projects-container
  .project-card
  .project-card-content-container {
  height: 15vh;
  background-color: #eeeeee;
}
.playground-projects-container
  .project-card
  .project-card-content-container
  .project-card-add-icon,
.playground-create-projects-container
  .project-card
  .project-card-content-container
  .project-card-add-icon {
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.playground-projects-container .project-card .project-card-content-content,
.playground-create-projects-container
  .project-card
  .project-card-content-content {
  padding: 0;
  display: flex;
  padding-left: 5%;
  align-items: center;
  padding: 0.5vh 1.5vh;
}
.playground-projects-container
  .project-card
  .project-card-content-content
  .project-card-title-content,
.playground-create-projects-container
  .project-card
  .project-card-content-content
  .project-card-title-content {
  display: flex;
  align-items: center;
  min-width: 80%;
}

.playground-projects-container
  .project-card
  .project-card-content-content
  .project-title,
.playground-create-projects-container
  .project-card
  .project-card-content-content
  .project-title {
  margin-bottom: 0;
  font-family: "rajdhani-semibold";
  line-height: 1 !important;
  font-size: 1rem !important;
  line-height: 1 !important;
  padding-left: 5%;
  width: calc(100% - 1em);
  overflow: hidden;
  word-wrap: break-word;
  text-align: start;
}

.playground-projects-container
  .project-card
  .project-card-content-content
  .project-delete-button,
.playground-create-projects-container
  .project-card
  .project-card-content-content
  .project-delete-button {
  transition: transform 0.2s ease-in-out;
  color: gray;
}

.playground-projects-container
  .project-card
  .project-card-content-content
  .project-delete-button:hover,
.playground-create-projects-container
  .project-card
  .project-card-content-content
  .project-delete-button:hover {
  transform: scale(1.1);
}

.playground-projects-container .project-card:hover,
.playground-create-projects-container .project-card:hover {
  transform: scale(1.05);
}

.playground-projects-container .user-project-card,
.playground-create-projects-container .user-project-card {
  margin: 0 1.5% 1.5% 0;
  width: 18%;
  border-radius: 3.5vh;
}

.playground-projects-container .create-project-card,
.playground-create-projects-container .create-project-card {
  width: 29% !important;
  margin: 0 1.5% 2% !important;
}

.playground-projects-container .selected-project-card,
.playground-create-projects-container .selected-project-card {
  transform: scale(1.1);
  border: 3px solid #4f4f4f !important;
}
.teacher-community-project-container {
  top: 8vh;
  position: absolute;
  width: 98vw;
  padding: 0px 1vw;
}
.no-community-projects {
  border-radius: 1vw;
  padding: 2vw;
  width: 100%;
  font-family: "rajdhani-medium";
}
.no-community-projects p {
  text-align: center;
  line-height: 2.2vw;
  font-size: 2vw;
  margin: 0;
}

.project-description-container {
  padding-bottom: 2vh;
}

.project-description-container #description-save-button {
  line-height: 2.2vw;
  font-size: 1.5vw;
  font-family: "rajdhani-medium";
  margin-top: 1vh;
}

.project-description-container .project-description-header {
  display: flex;
  padding-bottom: 1vh;
}

.project-description-container
  .project-description-header
  .project-description-title {
  margin: 0;
  font-size: 1.7vw;
  font-family: "rajdhani-semibold";
  align-self: center;
}

.project-description-container textarea {
  color: #000 !important;
  opacity: 0.8;
}
.select-project-header-container {
  display: flex;
  align-items: flex-start;
}

.create-project-headings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.create-project-headings.select-project-heading {
  font-family: "rajdhani-semibold" !important;
  font-size: 1.9rem !important;
}
.create-project-headings.project-name-heading {
  font-family: "rajdhani-medium" !important;
  font-size: 1.8rem !important;
  align-items: flex-start;
  margin-top: 0.6rem !important;
}
.create-project-modal > .MuiDialog-container > .MuiPaper-root {
  background-color: white !important;
}
.create-project-dialog {
  padding: 0 0 0 25px !important;
  margin-bottom: 1.5rem !important;
}

.create-project-dialog .playground-create-projects-container {
  height: 25vh;
  overflow: auto;
  padding-top: 1.5vh !important;
}

.create-project-dialog
  .playground-create-projects-container
  .create-project-card {
  border: none;
  position: relative !important;
  border-radius: 3.5vh !important;
  padding: 0 !important;
}

.create-project-dialog
  .playground-create-projects-container
  .create-project-card
  .project-card-content-content {
  background-color: #fe7d00 !important;
}

.create-project-dialog .MuiOutlinedInput-root {
  font-family: "rajdhani-semibold";
  font-size: larger;
  border-radius: 0.7rem;
}

.create-project-dialog .MuiOutlinedInput-root input {
  padding: 2px 1rem;
  caret-color: black;
  font-size: 1.5rem;
  -webkit-text-fill-color: black !important;
}

.create-project-dialog .project-not-selected {
  background-color: #e2e7ea;
}

.create-project-dialog .project-not-selected fieldset {
  border-style: none;
}

.create-project-dialog .project-not-selected input {
  color: #bcbbbb;
  -webkit-text-fill-color: #bcbbbb !important;
}

.create-project-dialog .reach-limit-message-container {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin-right: 25px;
  flex-direction: column;
  align-items: center;
}

.create-project-dialog .reach-limit-message-container p {
  margin: 0 !important;
  line-height: 3rem;
  text-align: center;
}

.create-project-action-container {
  justify-content: end !important;
  margin-right: 1.5rem !important;
}

.project-name-input-container {
  max-width: 60%;
  min-width: 50%;
}

.create-project-error-message {
  color: red;
  margin: 0px;
  font-size: 1.25rem;
  margin-top: 0.5rem;
  font-family: "rajdhani-medium";
  margin-left: 1rem;
}

/* Python playground styles */
.playground-container #python-publish-button,
.playground-container #python-delete-button {
  line-height: 2.2vw;
  font-size: 1.5vw;
  background-color: #ffb017;
  font-family: "rajdhani-medium";
}

.playground-container #python-publish-button {
  margin-right: 1vw;
}
.playground-python-editor-container {
  height: calc(100% - 1px);
  margin: 0;
  position: relative;
  border-top: 1px solid #d3d3d3;
}
.playground-graphic-window-panel .console-box {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}
.playground-graphic-window-panel .console {
  height: unset !important;
}
.project-cards-container {
  background: #dbdbdb !important;
  padding: 1vw 1.5vw !important;
  margin: 1vw 1vw !important;
  width: 100% !important;
  border-radius: 1vw !important;
}
