.dashboard-container{
  position: fixed;
  top: 8vh;
  overflow-y: auto;
  height: 92vh;
  width: 100%;
  display: flex;
  line-height: 1.5;
}
.dashboard-container *,.dashboard-container :after,.dashboard-container :before,
.progress-report-container *,.progress-report-container :after,.progress-report-container :before {
  box-sizing: border-box;
}
.admin-progress-header{
  font-family: 'rajdhani-semibold' !important;
  padding-left: 0.6rem;
  font-size: 1.5rem !important;
}