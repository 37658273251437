.curriculum-map-container {
  background-color: #dde9f5;
  height: 100%;
}
.curriculum-map {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  height: 100%;
}
.grid-container {
  height: 79vh;
  width: 100%;
  background-color: white;
  border-radius: 4px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  background: #85858747;
  z-index: 1000;
  height: 100%;
}
