@font-face {
  font-family: "rajdhani-regular";
  src: url("../../fonts/rajdhani-regular.ttf");
}

@font-face {
  font-family: "rajdhani-semibold";
  src: url("../../fonts/rajdhani-semibold.ttf");
}

@font-face {
  font-family: "rajdhani-bold";
  src: url("../../fonts/rajdhani-bold.ttf");
}

@font-face {
  font-family: "rajdhani-light";
  src: url("../../fonts/rajdhani-light.ttf");
}

@font-face {
  font-family: "rajdhani-medium";
  src: url("../../fonts/rajdhani-medium.ttf");
}

body {
  margin: 0;
  padding: 0;
}

.secondary-nav {
  position: fixed;
  top: 8vh;
  left: 0;
  right: 0;
  height: 16vh;
  background-color: #d1d526;
  z-index: 1000;
}
.col {
  padding: 0;
  height: 100%;
}
.secondary-nav .row {
  height: 100%;
}
.outer-cover {
  background: white;
  width: 12vh;
  height: 12vh;
  background-color: white;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  margin-left: 2vw;
}
.avatar-circle-main {
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-circle-main > img {
  height: 70%;
  padding: 0;
  margin: 0;
}
.personal-info p {
  padding: 0;
  margin: 0;
  font-family: "rajdhani-medium";
  font-size: 1.8vw;
  line-height: 2vw;
}
#student-fullname {
  font-weight: bold;
}

.student-grade {
  font-size: 1.3vw !important;
}

.module-nav svg {
  margin-left: 0;
  width: 70%;
  height: 100%;
  padding-top: 1vw;
  padding-bottom: 1vw;
}
#module-1,
#module-2,
#module-3,
#module-4,
#module-5,
#module-6,
#module-7,
#module-8,
#module-9,
#module-10,
#module-11,
#module-12,
#module-13,
#module-14,
#module-15 {
  cursor: pointer;
}
