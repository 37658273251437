.text-center {
  text-align: center;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col {
  padding: 0;
  height: 100%;
}
.text-center {
  text-align: center;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.align-items-end {
  align-items: flex-end;
}
.col-8 {
  width: 66.66%;
  /* float: left; */
}
.col-4 {
  width: 33.33%;
  /* float: left; */
}
.p-0 {
  padding: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.justify-content-start {
  justify-content: start !important;
}
.justify-content-end {
  justify-content: end;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.ml-auto {
  margin-left: auto !important;
}
.col-9 {
  /* -ms-flex: 0 0 75%; */
  flex: 0 0 75%;
  max-width: 75%;
}
.col-3 {
  /* -ms-flex: 0 0 25%; */
  flex: 0 0 25%;
  max-width: 25%;
}
.justify-content-between {
  justify-content: space-between !important;
}
.table-sm {
  font-size: 0.875rem;
}
.table-sm th,
.table-sm td {
  padding: 0.5rem;
}
.m-0 {
  margin: 0 !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.flex-row {
  flex-direction: row !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
button:disabled {
  pointer-events: none;
}
